export const carColors = {
        "Audi R8 LMS GT3": "rgb(151, 151, 151)",
        "Audi R8 LMS EVO II GT3": "rgb(151, 151, 151)",
        "Mercedes-AMG GT3 2020": "rgb(56, 144, 144)",
        "Ford GT GT3": "rgb(48, 62, 137)",
        "Ferrari 296 GT3": "rgb(176, 16, 48)",
        "McLaren MP4-12C GT3": "rgb(204, 112, 0)",
        "BMW M4 GT3": "rgb(0, 123, 174)",
        "Lamborghini Huracán GT3 EVO": "rgb(51, 134, 51)",
        "Porsche 911 GT3 R (992)": "#b08d57",
        "McLaren 570S GT4": "rgb(204, 112, 0)",
        "BMW M4 GT4": "rgb(0, 123, 174)",
        "Porsche 718 Cayman GT4 Clubsport MR": "#b08d57",
        "Mercedes-AMG GT4": "rgb(56, 144, 144)",
        "Aston Martin Vantage GT4": "rgb(11, 94, 11)",
        "BMW M Hybrid V8": "rgb(0, 73, 124)",
        "Dallara P217": "rgb(121, 50, 175)",
        "Hyundai Elantra N TCR": "rgb(0, 46, 106)",
        "Porsche 963 GTP": "rgb(0, 46, 106)",
        "Audi RS 3 LMS TCR": "rgb(151, 151, 151)",
        "Hyundai Veloster N TCR": "rgb(40, 54, 162)",
        "Honda Civic Type R TCR": "rgb(163, 0, 0)",
        "Cadillac V-Series.R GTP": "rgb(203, 165, 41)",
        "Ligier JS P320": "#4f72a8",
        "Toyota GR86": "#EB0A1E",
        "Acura ARX-06 GTP": "#EB0A1E",
        "Global Mazda MX-5 Cup": "rgb(0, 73, 124)",
        "Ford Mustang FR500S": "rgb(0, 46, 106)",
        "Renault Clio": "rgb(188, 156, 0)",
        "Porsche 911 RSR": "#b08d57",
        "Chevrolet Corvette C8.R GTE": "rgb(100, 100, 100)",
        "BMW M8 GTE": "rgb(0, 123, 174)",
        "Ferrari 488 GTE": "rgb(176, 16, 48)",
        "Ford GTE": "rgb(48, 62, 137)",
        "Ford Mustang GT3": "rgb(0,0,205)",
        "Chevrolet Corvette Z06 GT3.R": "#C329E7",
        "Super Formula SF23 - Toyota": "#EB0A1E",
};
